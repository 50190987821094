import * as MUTATE from '../mutation-types';
import * as ACT from '../action-types';
import { IState, LoadState } from '../types';
import statsService from '@/api/stats.service';
import { LOAD_STATE } from '../shared';
import { generateFetch } from '@/store/generators';

const ChurnMetricsDashboardModule = {
  state: {
    billingMetricsByTimeInterval: [],
    billingMetricsLoadState: LOAD_STATE.UNLOADED,
  },
  actions: {
    [ACT.CHURN_METRICS_LOAD_BILLING_METRICS]: generateFetch({
        getLoadState: (state: IState) => state.billingMetricsLoadState,
        setLoadStateMutation: MUTATE.SET_BILLING_METRICS_LOAD_STATE,
        fetchValue: async () => {
          return await statsService.billingMetricsTimeSeries({});
        },
        setValueMutation: MUTATE.SET_BILLING_METRICS_BY_TIME_INTERVAL,
      }),
    },
  mutations: {
    [MUTATE.SET_BILLING_METRICS_BY_TIME_INTERVAL](state: IState, payload: any[]) {
      state.billingMetricsByTimeInterval = payload;
    }, 
    [MUTATE.SET_BILLING_METRICS_LOAD_STATE](state: IState, loadState: LoadState) {
      state.billingMetricsLoadState = loadState;
    },
  },
  getters: {
    billingMetricsByTimeInterval(state: IState) {
      return state.billingMetricsByTimeInterval;
    },
    billingMetricsLoadState(state: IState) {
      return state.billingMetricsLoadState;
    },
  },
};

export default ChurnMetricsDashboardModule;
