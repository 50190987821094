import Vue from 'vue';
import Axios, { AxiosRequestConfig } from 'axios';
import VuePhoneNumberInput from 'vue-phone-number-input';
import VueHighlightJS from 'vue-highlightjs';
import VueClipboard from 'vue-clipboard2';
import VueGtm from 'vue-gtm';
import EventBus from '@/helpers/event-bus';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import * as actions from '@/store/action-types';
import '@/style/main.scss';
import '@/helpers/timeAgo';
import '@/helpers/dateDisplay';
import '@/plugins/fontawesome';
import '@/plugins/element/element';
import '@/plugins/element/element-variables.scss';
import '@/assets/css/tailwind.css';
import '@/assets/css/highlightjs.css';
import './mixins/permissions-mixin';
import './directives/can';
import '@/plugins/apexcharts/apexcharts';
import * as Sentry from '@sentry/vue';
import { truncate } from '@/helpers/jsUtils';

const isProd = import.meta.env.PROD;
Vue.use(VueHighlightJS);
Vue.use(VueClipboard); // for copying text to user clipboard
Vue.use(VueGtm, { id: 'GTM-5M72WPM', debug: !isProd });
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.filter('truncate', truncate);

Vue.config.productionTip = false;

// return data directly
const formatResponse = (res: { data: any }) => res.data;

// intercept errors and display error states
// eslint-disable-next-line func-names
const intercept = (err: any) => {
  const { response } = err;
  return new Promise(() => {
    if (response && response.status === 422) {
      // handle expired JWT
      EventBus.$emit('alert', 'Authentication Error', 'Please try logging in again');
      return;
    }
    if (response && response.status === 401) {
      // If jwt is bad, cache route to return after re-authenticating
      if (response.data === 'Session Expired' || response.data === 'jwt expired') {
        EventBus.$emit('alert', 'Session Expired', 'Please log in again');
        // Clear expired auth data
        store.dispatch('CLEAR_TOKEN');
      } else {
        // Capture and display other auth errors
        // EventBus.$emit('alert', 'Session Expired', response.data.message);
      }
    }
    throw err;
  });
};
const token = localStorage.getItem('token');
if (token) {
  Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const asOrgInterceptor = (config: AxiosRequestConfig) => {
  if (store.state.asOrg) {
    config.params = {
      ...config.params,
      as_org: store.state.asOrg,
    };
  }
  return config;
};

// Axios configuration
Axios.interceptors.request.use(asOrgInterceptor);
Axios.interceptors.response.use(formatResponse, intercept);

const API_BASE_URL = import.meta.env.VITE_API_ENDPOINT || 'https://api.churnkey.co/v1';
Axios.defaults.baseURL = API_BASE_URL;

// Resync user record if refreshing page while logged in
if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
  store.dispatch(actions.SYNC_USER_RECORD);
}

Vue.directive('disable-all', {
  bind(el: any, binding: any) {
    const tags = ['input', 'button', 'textarea', 'select'];
    tags.forEach((tagName) => {
      const nodes = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        (nodes[i] as any).setAttribute('disabled', '');
      }
    });
  },
});

Object.defineProperty(Vue.prototype, '$tsStore', {
  get: function (this: Vue) {
    return this.$store;
  },
});

if (import.meta.env.PROD) {
  Sentry.init({
    Vue,
    dsn: 'https://c75c98bba441f04c08a7dea010b20162@o1329499.ingest.us.sentry.io/4507390707433472',
    integrations: [Sentry.browserTracingIntegration({ router, routeLabel: 'path' }), Sentry.replayIntegration()],
    tunnel: `${API_BASE_URL}/sentry`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [API_BASE_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
