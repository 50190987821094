/// Mix Panel Event Tracking

import mixpanel from 'mixpanel-browser';

export function trackEvent(event: string, properties: Record<string, any> = {}) {
  mixpanel.track(event, properties);
}


/**
 * Contains all enum values used for telemetry.
 *
 * **Note when adding new enum values:**
 * - add within the existing enum below
 * - add general term comment for all new categories
 * - follow this format: `CATEGORY:EVENT_NAME`,
 * where `CATEGORY` is a general category in the system and
 * `EVENT_NAME` is the action that is taken.
 */

export enum TelemetryEvents {

    // General Events
    SIGN_UP_SUBMITTED = 'SIGN_UP:SIGN_UP_SUBMITTED',
    
    // Demo Events
    DEMO_BOOKED = 'DEMO:BOOKED_DEMO'
}
