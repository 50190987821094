export const CLEAR_STATE = 'CLEAR_STATE';
export const ADMIN_CLEAR_STATE = 'ADMIN_CLEAR_STATE';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_USER = 'SET_USER';
export const SET_ORG = 'SET_ORG';
export const SET_ORG_DETAILS = 'SET_ORG_DETAILS';
export const SET_STRIPE_COUPONS = 'SET_STRIPE_COUPONS';
export const ADD_STRIPE_COUPON = 'ADD_STRIPE_COUPON';
export const SET_STRIPE_COUPONS_LOAD_STATE = 'SET_STRIPE_COUPONS_LOAD_STATE';
export const SET_STRIPE_PRICES = 'SET_STRIPE_PRICES';
export const SET_STRIPE_PRICES_LOAD_STATE = 'SET_STRIPE_PRICES_LOAD_STATE';
export const SET_STRIPE_PRODUCTS = 'SET_STRIPE_PRODUCTS';
export const SET_STRIPE_PRODUCTS_LOAD_STATE = 'SET_STRIPE_PRODUCTS_LOAD_STATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SYNC_USER_LOAD_STATE = 'SET_SYNC_USER_LOAD_STATE';
export const SET_SYNC_ORG_LOAD_STATE = 'SET_SYNC_ORG_LOAD_STATE';
export const SET_AUTH_2FA_REQUIRED = 'SET_AUTH_2FA_REQUIRED';
export const SET_AUTH_2FA_ERROR_MESSAGE = 'SET_AUTH_2FA_ERROR_MESSAGE';
export const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_ORG_NOTIFICATIONS = 'SET_ORG_NOTIFICATIONS';
export const STORE_WEBHOOK_DATA = 'STORE_WEBHOOK_DATA';
export const SET_BLUEPRINT_STEP = 'SET_BLUEPRINT_STEP';
export const UPDATE_BLUEPRINT_SURVEY = 'UPDATE_BLUEPRINT_SURVEY';
export const SET_CURRENT_BLUEPRINT_ID = 'SET_CURRENT_BLUEPRINT_ID';
export const STORE_BLUEPRINT_DATA = 'STORE_BLUEPRINT_DATA';
export const STORE_BLUEPRINT_DATA_FROM_RAW = 'STORE_BLUEPRINT_DATA_FROM_RAW';
export const STORE_SEGMENT_DATA = 'STORE_SEGMENT_DATA';
export const STORE_ABTEST_DATA = 'STORE_ABTEST_DATA';
export const SET_SEGMENT_FILTER = 'SET_SEGMENT_FILTER';
export const REMOVE_SEGMENT_FILTER = 'REMOVE_SEGMENT_FILTER';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_PROMO = 'SET_PROMO';
export const SET_PRODUCT_INTENT = 'SET_PRODUCT_INTENT';
export const SET_AS_ORG = 'SET_AS_ORG';
export const SET_SELECTED_PLAN_TYPE = 'SET_SELECTED_PLAN_TYPE';
export const SET_CONFIDENTIAL = 'SET_CONFIDENTIAL';
export const SET_TEST_MODE = 'SET_TEST_MODE';
export const SET_ISSUES = 'SET_ISSUES';
export const SET_ORGS = 'SET_ORGS';

// DASHBOARD
export const SET_ANALYTICS_SNAPSHOTS = 'SET_ANALYTICS_SNAPSHOTS';
export const SET_ANALYTICS_SNAPSHOTS_LOAD_STATE = 'SET_ANALYTICS_SNAPSHOTS_LOAD_STATE';
export const SET_BOOSTED_REVENUE_STATS = 'SET_BOOSTED_REVENUE_STATS';
export const SET_BOOSTED_REVENUE_STATS_LOAD_STATE = 'SET_BOOSTED_REVENUE_STATS_LOAD_STATE';
export const SET_COUPON_STATS = 'SET_COUPON_STATS';
export const SET_COUPON_STATS_LOAD_STATE = 'SET_COUPON_STATS_LOAD_STATE';
export const SET_PAUSE_STATS = 'SET_PAUSE_STATS';
export const SET_PAUSE_STATS_LOAD_STATE = 'SET_PAUSE_STATS_LOAD_STATE';
export const SET_REACTIVATION_STATS = 'SET_REACTIVATION_STATS';
export const SET_REACTIVATION_STATS_LOAD_STATE = 'SET_REACTIVATION_STATS_LOAD_STATE';
export const SET_SESSIONS = 'SET_SESSIONS';
export const SET_SESSIONS_LOAD_STATE = 'SET_SESSIONS_LOAD_STATE';
export const SET_DASHBOARD_DATE_RANGE = 'SET_DASHBOARD_DATE_RANGE';
export const SET_SESSION_OUTCOMES = 'SET_SESSION_OUTCOMES';
export const SET_SESSION_OUTCOMES_LOAD_STATE = 'SET_SESSION_OUTCOMES_LOAD_STATE';
export const SET_OFFER_OUTCOMES = 'SET_OFFER_OUTCOMES';
export const SET_OFFER_OUTCOMES_LOAD_STATE = 'SET_OFFER_OUTCOMES_LOAD_STATE';
export const SET_SURVEY_RESPONSES = 'SET_SURVEY_RESPONSES';
export const SET_SURVEY_RESPONSES_LOAD_STATE = 'SET_SURVEY_RESPONSES_LOAD_STATE';
export const SET_RECENT_SAVES = 'SET_RECENT_SAVES';
export const SET_RECENT_SAVES_LOAD_STATE = 'SET_RECENT_SAVES_LOAD_STATE';
export const SET_RECENT_SESSIONS = 'SET_RECENT_SESSIONS';
export const SET_RECENT_SESSIONS_LOAD_STATE = 'SET_RECENT_SESSIONS_LOAD_STATE';
export const SET_TEST_SESSIONS = 'SET_TEST_SESSIONS';
export const SET_TEST_SESSIONS_LOAD_STATE = 'SET_TEST_SESSIONS_LOAD_STATE';
export const SET_SESSION_FILTER_VALUES = 'SET_SESSION_FILTER_VALUES';
export const SET_SESSION_FILTER_VALUES_LOAD_STATE = 'SET_SESSION_FILTER_VALUES_LOAD_STATE';
export const SET_PREVIEW_INLINE = 'SET_PREVIEW_INLINE';
export const SET_CUSTOMER_PROFILE = 'SET_CUSTOMER_PROFILE';
export const SET_CUSTOMER_HEALTH = 'SET_CUSTOMER_HEALTH';
export const SET_CUSTOMER_PROFILE_LOAD_STATE = 'SET_CUSTOMER_PROFILE_LOAD_STATE';
export const SET_AB_SESSION_COUNTS = 'SET_AB_SESSION_COUNTS';
export const SET_AB_SESSION_COUNTS_LOAD_STATE = 'SET_AB_SESSION_COUNTS_LOAD_STATE';
export const SET_SEGMENT_SESSION_COUNTS = 'SET_SEGMENT_SESSION_COUNTS';
export const SET_SEGMENT_SESSION_COUNTS_LOAD_STATE = 'SET_SEGMENT_SESSION_COUNTS_LOAD_STATE';
export const ADMIN_STORE_ORG_DATA = 'ADMIN_STORE_ORG_DATA';

// DUNNING
export const STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA = 'STORE_DUNNING_CAMPAIGN_BLUEPRINT_DATA';
export const STORE_DUNNING_CAMPAIGN_BLUEPRINT_EMAIL_COUNT = 'STORE_DUNNING_CAMPAIGN_BLUEPRINT_EMAIL_COUNT';
export const SET_DUNNING_CAMPAIGNS_COUNT = 'SET_DUNNING_CAMPAIGNS_COUNT';
export const SET_DUNNING_CAMPAIGNS_COUNT_LOAD_STATE = 'SET_DUNNING_CAMPAIGNS_COUNT_LOAD_STATE';
export const SET_CURRENT_DUNNING_CAMPAIGN_BLUEPRINT_ID = 'SET_CURRENT_DUNNING_CAMPAIGN_BLUEPRINT_ID';
export const SET_CURRENT_DUNNING_EMAIL_BLUEPRINT_ID = 'SET_CURRENT_DUNNING_EMAIL_BLUEPRINT_ID';
export const SET_DUNNING_CAMPAIGN_SMS = 'SET_DUNNING_CAMPAIGN_SMS'; // UPDATE SMS
export const SET_DUNNING_CAMPAIGN_EMAIL = 'SET_DUNNING_CAMPAIGN_EMAIL'; // UPDATE EMAIL
export const STORE_DUNNING_SMS_DATA = 'STORE_DUNNING_SMS_DATA'; // UPDATE SMS
export const STORE_DUNNING_EMAIL_DATA = 'STORE_DUNNING_EMAIL_DATA'; // UPDATE EMAIL
export const STORE_DUNNING_SETTINGS = 'STORE_DUNNING_SETTINGS';
export const STORE_DUNNING_EXCLUSION_DATA = 'STORE_DUNNING_EXCLUSION_DATA';
export const STORE_DUNNING_EXCLUSION_BLOCK_COUNT = 'STORE_DUNNING_EXCLUSION_BLOCK_COUNT';

// DUNNING DASHBOARD
export const SET_EMAIL_TIMELINE = 'SET_EMAIL_TIMELINE';
export const SET_EMAIL_TIMELINE_LOAD_STATE = 'SET_EMAIL_TIMELINE_LOAD_STATE';

// REACTIVATION
export const STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA = 'STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_DATA';
export const STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_EMAIL_COUNT = 'STORE_REACTIVATION_CAMPAIGN_BLUEPRINT_EMAIL_COUNT';
export const SET_CURRENT_REACTIVATION_CAMPAIGN_BLUEPRINT_ID = 'SET_CURRENT_REACTIVATION_CAMPAIGN_BLUEPRINT_ID';
export const SET_CURRENT_REACTIVATION_EMAIL_BLUEPRINT_ID = 'SET_CURRENT_REACTIVATION_EMAIL_BLUEPRINT_ID';
export const SET_REACTIVATION_CAMPAIGN_EMAIL = 'SET_REACTIVATION_CAMPAIGN_EMAIL';
export const STORE_REACTIVATION_EMAIL_DATA = 'STORE_DUNNING_EMAIL_DATA';
export const STORE_REACTIVATION_SETTINGS = 'STORE_REACTIVATION_SETTINGS';
export const STORE_REACTIVATION_EXCLUSION_DATA = 'STORE_REACTIVATION_EXCLUSION_DATA';
// export const STORE_REACTIVATION_EXCLUSION_BLOCK_COUNT = 'STORE_REACTIVATION_EXCLUSION_BLOCK_COUNT';
export const SET_REACTIVATION_CAMPAIGNS_COUNT = 'SET_REACTIVATION_CAMPAIGNS_COUNT';
export const SET_REACTIVATION_CAMPAIGNS_COUNT_LOAD_STATE = 'SET_REACTIVATION_CAMPAIGNS_COUNT_LOAD_STATE';
export const SET_REACTIVATION_BLOCK_COUNT = 'SET_REACTIVATION_BLOCK_COUNT';
export const SET_REACTIVATION_BLOCK_COUNT_LOAD_STATE = 'SET_REACTIVATION_BLOCK_COUNT_LOAD_STATE';
export const SET_REACTIVATION_EMAIL_OPEN_COUNT = 'SET_REACTIVATION_EMAIL_OPEN_COUNT';
export const SET_REACTIVATION_EMAIL_OPEN_COUNT_LOAD_STATE = 'SET_REACTIVATION_EMAIL_OPEN_COUNT_LOAD_STATE';
export const SET_RECENT_SURVEY_RESPONSES = 'SET_RECENT_SURVEY_RESPONSES';
export const SET_RECENT_SURVEY_RESPONSES_LOAD_STATE = 'SET_RECENT_SURVEY_RESPONSES_LOAD_STATE';
export const SET_REACTIVATION_EMAIL_TIMELINE = 'SET_REACTIVATION_EMAIL_TIMELINE';
export const SET_REACTIVATION_EMAIL_TIMELINE_LOAD_STATE = 'SET_REACTIVATION_EMAIL_TIMELINE_LOAD_STATE';

// SUBSCRIPTION ANALYTICS
export const SET_EVENT_ROLLUP = 'SET_EVENT_ROLLUP';
export const SET_EVENT_ROLLUP_LOAD_STATE = 'SET_EVENT_ROLLUP_LOAD_STATE';
export const SET_EVENT_FILTER_OPTIONS = 'SET_EVENT_FILTER_OPTIONS';
export const SET_EVENT_FILTER_OPTIONS_LOAD_STATE = 'SET_EVENT_FILTER_OPTIONS_LOAD_STATE';

// MERGE FIELDS
export const SET_PREVIEW_MERGE_FIELDS = 'SET_PREVIEW_MERGE_FIELDS';

// AI
export const SET_AI_JOBS = 'SET_AI_JOBS';
export const ADD_AI_JOB = 'ADD_AI_JOB';
export const UPDATE_AI_JOB = 'UPDATE_AI_JOB';

// CHURN METRICS
export const SET_BILLING_METRICS_BY_TIME_INTERVAL = 'SET_BILLING_METRICS_BY_TIME_INTERVAL';
export const SET_BILLING_METRICS_LOAD_STATE = 'SET_BILLING_METRICS_LOAD_STATE';